import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				PerfectCut Barbershop
			</title>
			<meta name={"description"} content={"Сделайте шаг в мир, где традиции встречаются с трендами, а каждая стрижка – символ стиля и изящества. В PerfectCut Barbershop мы верим, что отличная стрижка является краеугольным камнем прекрасного дня. Независимо от того, готовитесь ли вы к важному событию или просто хотите обновить свой образ, наша дверь открыта, чтобы предоставить вам непревзойденный опыт ухода."} />
			<meta property={"og:title"} content={"PerfectCut Barbershop"} />
			<meta property={"og:description"} content={"Сделайте шаг в мир, где традиции встречаются с трендами, а каждая стрижка – символ стиля и изящества. В PerfectCut Barbershop мы верим, что отличная стрижка является краеугольным камнем прекрасного дня. Независимо от того, готовитесь ли вы к важному событию или просто хотите обновить свой образ, наша дверь открыта, чтобы предоставить вам непревзойденный опыт ухода."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-3.jpg?v=2024-06-17T10:57:08.087Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="box3" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="box" />
		</Components.Header23>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-1.jpg?v=2024-06-17T10:57:08.119Z"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-1.jpg?v=2024-06-17T10%3A57%3A08.119Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-1.jpg?v=2024-06-17T10%3A57%3A08.119Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-1.jpg?v=2024-06-17T10%3A57%3A08.119Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-1.jpg?v=2024-06-17T10%3A57%3A08.119Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-1.jpg?v=2024-06-17T10%3A57%3A08.119Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-1.jpg?v=2024-06-17T10%3A57%3A08.119Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-1.jpg?v=2024-06-17T10%3A57%3A08.119Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11:09:03.586Z"
							srcSet="https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://uploads.quarkly.io/66701633fdcae00021e37692/images/1-4.jpg?v=2024-06-17T10:57:08.079Z"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
							srcSet="https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-4.jpg?v=2024-06-17T10%3A57%3A08.079Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-4.jpg?v=2024-06-17T10%3A57%3A08.079Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-4.jpg?v=2024-06-17T10%3A57%3A08.079Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-4.jpg?v=2024-06-17T10%3A57%3A08.079Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-4.jpg?v=2024-06-17T10%3A57%3A08.079Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-4.jpg?v=2024-06-17T10%3A57%3A08.079Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/1-4.jpg?v=2024-06-17T10%3A57%3A08.079Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-1.jpg?v=2024-06-14T11:09:03.583Z"
							position="absolute"
							width="100%"
							left={0}
							srcSet="https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-1.jpg?v=2024-06-14T11%3A09%3A03.583Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-1.jpg?v=2024-06-14T11%3A09%3A03.583Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-1.jpg?v=2024-06-14T11%3A09%3A03.583Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-1.jpg?v=2024-06-14T11%3A09%3A03.583Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-1.jpg?v=2024-06-14T11%3A09%3A03.583Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-1.jpg?v=2024-06-14T11%3A09%3A03.583Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-1.jpg?v=2024-06-14T11%3A09%3A03.583Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-4%20%281%29.jpg?v=2024-06-14T11:09:03.578Z"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
							srcSet="https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-4%20%281%29.jpg?v=2024-06-14T11%3A09%3A03.578Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-4%20%281%29.jpg?v=2024-06-14T11%3A09%3A03.578Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-4%20%281%29.jpg?v=2024-06-14T11%3A09%3A03.578Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-4%20%281%29.jpg?v=2024-06-14T11%3A09%3A03.578Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-4%20%281%29.jpg?v=2024-06-14T11%3A09%3A03.578Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-4%20%281%29.jpg?v=2024-06-14T11%3A09%3A03.578Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-4%20%281%29.jpg?v=2024-06-14T11%3A09%3A03.578Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text margin="0px 0px 1rem 0px" font="--headline2" color="--light">
					Про PerfectCut
				</Text>
				<Text margin="0px 0px 1rem 0px" font="--headline5" color="--light">
					Парикмахерская Clip&Clove Barbershop, расположенная в самом центре совершенного ухода, предлагает отдых от ежедневной суеты. Благодаря команде опытных парикмахеров и стилистов, преданных своему делу, мы предоставляем широкий спектр услуг, адаптированных к уникальному стилю и предпочтениям каждого клиента. От классических кроев до современных выцветаний, каждая услуга выполняется с точностью и вниманием к деталям, что гарантирует, что вы исходите с уверенностью и выглядите лучше.
				</Text>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
			>
				<Text
					font="--headline4"
					color="--light"
					margin="0px 2rem 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Сделайте шаг в мир, где традиции встречаются с трендами, а каждая стрижка – символ стиля и изящества.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					font="--headline4"
					color="--light"
					margin="0px 2rem 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					В PerfectCut Barbershop мы верим, что отличная стрижка является краеугольным камнем прекрасного дня. Независимо от того, готовитесь ли вы к важному событию или просто хотите обновить свой образ, наша дверь открыта, чтобы предоставить вам непревзойденный опыт ухода.
				</Text>
			</Box>
		</Section>
		<Section padding="0 0 0 0" background="--color-darkL2" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 40px 25px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-padding="80px 25px 80px 25px"
					background="--color-darkL2"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Text
							margin="0px 0px 20px 0px"
							font="normal 400 56px/1.2 --fontFamily-serifGaramond"
							sm-font="normal 700 42px/1.2 --fontFamily-sans"
							color="--light"
							lg-margin="0px 0px 35px 0px"
						>
							Наша приверженность к качеству
						</Text>
						<Text margin="0px 0px 25px 0px" font="--lead" color="#e8e4e3" padding="0px 35px 0px 0px">
							У PerfectCut мы не просто стрижем волосы – мы создаем впечатление. Наша парикмахерская создана как простор отдыха и трансформации. Используя только лучшие средства по уходу, мы гарантируем, что каждый визит улучшит вашу процедуру ухода и улучшит ваш стиль.
						</Text>
					</Box>
				</Box>
				<Image
					src="https://uploads.quarkly.io/66701633fdcae00021e37692/images/2-2.jpg?v=2024-06-17T10:57:08.081Z"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
					max-height="100vh"
					srcSet="https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/2-2.jpg?v=2024-06-17T10%3A57%3A08.081Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/2-2.jpg?v=2024-06-17T10%3A57%3A08.081Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/2-2.jpg?v=2024-06-17T10%3A57%3A08.081Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/2-2.jpg?v=2024-06-17T10%3A57%3A08.081Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/2-2.jpg?v=2024-06-17T10%3A57%3A08.081Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/2-2.jpg?v=2024-06-17T10%3A57%3A08.081Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66701633fdcae00021e37692/images/2-2.jpg?v=2024-06-17T10%3A57%3A08.081Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" background="--color-darkL2" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 40px 25px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-padding="80px 25px 80px 25px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Text margin="0px 0px 25px 0px" font="--lead" color="#e8e4e3" padding="0px 35px 0px 0px">
							Наши парикмахеры — художники, хорошо владеющие как классическими техниками, так и последними тенденциями.
Мы прислушиваемся к вашим потребностям и предоставляем индивидуальные советы и решения.
Ваше здоровье и безопасность наиболее важны. Мы придерживаемся строгих гигиенических протоколов.
Только лучше всего для наших клиентов, чтобы питать и защищать ваши волосы. Чувство сообщества: гостеприимная атмосфера, заставляющая каждого чувствовать себя частью семьи Clip&Clove.
						</Text>
					</Box>
				</Box>
				<Image
					src="https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
					order="-1"
					max-height="100vh"
					srcSet="https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" background="--color-darkL2" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 40px 25px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-padding="80px 25px 80px 25px"
					background="--color-darkL2"
					align-items="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Text margin="0px 0px 25px 0px" font="--headline4" color="#e8e4e3" padding="0px 35px 0px 0px">
							Посетите нас сегодня и узнайте, почему мы являемся лучшим выбором для привередливых джентльменов и людей, стремящихся к стилю в этом районе.
						</Text>
					</Box>
				</Box>
				<Image
					src="https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11:09:03.585Z"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
					max-height="100vh"
					srcSet="https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer1>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Footer1>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});